import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';
import { CyclePhaseType } from '@repo/types/src/dashboard';
import { useState } from 'react';
import {
  ChartContainer,
  ChartTooltipContent,
  ChartTooltip,
  type ChartConfig,
} from '@/components/ui/chart';
import { useGetSymptomDistribution } from '@/api/dashboard/dashboard.hooks';
import { ChartSkeleton } from '@/components/dashboard/chart-skeleton';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CyclePhaseFr } from '../utils';

const chartConfig = {
  users: {
    label: "Nombre d'utilisatrices",
  },
  label: {
    color: 'white',
  },
  'period-symptom': {
    color: 'hsl(var(--chart-1))',
  },
  'vaginal-discharge': {
    color: 'hsl(var(--chart-2))',
  },
  mood: {
    color: 'hsl(var(--chart-3))',
  },
  'sexual-activity': {
    color: 'hsl(var(--chart-4))',
  },
  'digestive-symptom': {
    color: 'hsl(var(--chart-5))',
  },
} satisfies ChartConfig;

enum SymptomEnum {
  'ABDOMINAL_PAIN' = 'Douleurs abdominales',
  'CRAVINGS' = 'Fringales',
  'ACNE' = 'Acnée',
  'TENDER_BREASTS' = 'Seins sensibles',
  'HEADACHE' = 'Mal de tête',
  'BACK_PAIN' = 'Mal de dos',
  'CRAMPS' = 'Crampes',
  'FATIGUE' = 'Fatigue',
  'INSOMNIA' = 'Insomnie',
  'VAGINAL_ITCHING' = 'Démangeaisons vaginales',
  'VAGINAL_DRYNESS' = 'Sécheresse vaginale',
  'NO_DISCHARGE' = 'Pas de perte',
  'CREAMY' = 'Pertes crèmeuses',
  'WATERY' = 'Pertes aqueuses',
  'STICKY' = 'Pertes visqueuses',
  'EGG_WHITE' = "Pertes blanc d'oeuf",
  'SPOTTING' = 'Spotting',
  'UNUSUAL' = 'Pertes inhabituelles',
  'WHITE_CLUMPY' = 'Pertes blanches grumeleuses',
  'GREY' = 'Pertes grises',
  'CALM' = 'Calme',
  'HAPPY' = 'Heureuse',
  'ENERGETIC' = 'Énergique',
  'PLAYFUL' = 'Enjouée',
  'MOOD_SWINGS' = "Sautes d'humeur",
  'IRRITABLE' = 'Irritable',
  'SAD' = 'Triste',
  'ANXIOUS' = 'Angoissée',
  'DEPRESSED' = 'Deprimée',
  'GUILTY' = 'Culpabilité',
  'OBSESSIVE_THOUGHTS' = 'Pensées obsessionnelles',
  'LOW_ENERGY' = "Manque d'énergie",
  'LETHARGIC' = 'Léthargique',
  'LOST' = 'Perdue',
  'SELF_CRITICAL' = 'Très autocritique',
  'NO_INTERCOURSE' = 'Pas de rapport',
  'PROTECTED_INTERCOURSE' = 'Rapports protégés',
  'UNPROTECTED_INTERCOURSE' = 'Rapports non protégés',
  'HIGH_LIBIDO' = 'Forte libido',
  'NEUTRAL_LIBIDO' = 'Libido neutre',
  'LOW_LIBIDO' = 'Faible libido',
  'NAUSEA' = 'Nausées',
  'BLOATING' = 'Ballonnements',
  'CONSTIPATION' = 'Constipation',
  'DIARRHEA' = 'Diarrhée',
}

enum SymptomCategory {
  'ABDOMINAL_PAIN' = 'period-symptom',
  'CRAVINGS' = 'period-symptom',
  'ACNE' = 'period-symptom',
  'TENDER_BREASTS' = 'period-symptom',
  'HEADACHE' = 'period-symptom',
  'BACK_PAIN' = 'period-symptom',
  'CRAMPS' = 'period-symptom',
  'FATIGUE' = 'period-symptom',
  'INSOMNIA' = 'period-symptom',
  'VAGINAL_ITCHING' = 'period-symptom',
  'VAGINAL_DRYNESS' = 'period-symptom',
  'NO_DISCHARGE' = 'vaginal-discharge',
  'CREAMY' = 'vaginal-discharge',
  'WATERY' = 'vaginal-discharge',
  'STICKY' = 'vaginal-discharge',
  'EGG_WHITE' = 'vaginal-discharge',
  'SPOTTING' = 'vaginal-discharge',
  'UNUSUAL' = 'vaginal-discharge',
  'WHITE_CLUMPY' = 'vaginal-discharge',
  'GREY' = 'vaginal-discharge',
  'CALM' = 'mood',
  'HAPPY' = 'mood',
  'ENERGETIC' = 'mood',
  'PLAYFUL' = 'mood',
  'MOOD_SWINGS' = 'mood',
  'IRRITABLE' = 'mood',
  'SAD' = 'mood',
  'ANXIOUS' = 'mood',
  'DEPRESSED' = 'mood',
  'GUILTY' = 'mood',
  'OBSESSIVE_THOUGHTS' = 'mood',
  'LOW_ENERGY' = 'mood',
  'LETHARGIC' = 'mood',
  'LOST' = 'mood',
  'SELF_CRITICAL' = 'mood',
  'NO_INTERCOURSE' = 'sexual-activity',
  'PROTECTED_INTERCOURSE' = 'sexual-activity',
  'UNPROTECTED_INTERCOURSE' = 'sexual-activity',
  'HIGH_LIBIDO' = 'sexual-activity',
  'NEUTRAL_LIBIDO' = 'sexual-activity',
  'LOW_LIBIDO' = 'sexual-activity',
  'NAUSEA' = 'digestive-symptom',
  'BLOATING' = 'digestive-symptom',
  'CONSTIPATION' = 'digestive-symptom',
  'DIARRHEA' = 'digestive-symptom',
}

export const SymptomBarChart = () => {
  const [selectedCyclePhase, setSelectedCyclePhase] =
    useState<CyclePhaseType>('all-time');
  const { distribution, usersCount, isError, isLoading } =
    useGetSymptomDistribution(selectedCyclePhase);

  const formattedDistribution = distribution
    ?.map((symptom) => {
      const category = SymptomCategory[symptom.symptom];
      return {
        ...symptom,
        category,
        symptom: SymptomEnum[symptom.symptom],
        label: SymptomEnum[symptom.symptom],
        fill: `var(--color-${category})`,
      };
    })
    .sort((a, b) => {
      const categoryComparison = a.category.localeCompare(b.category);
      if (categoryComparison !== 0) return categoryComparison;
      return a.users - b.users;
    });

  return (
    <div className="w-full h-full">
      {isLoading || isError ? (
        <ChartSkeleton />
      ) : (
        <Card className="h-full">
          <CardHeader>
            <CardTitle>Répartition des utilisatrices par symptômes</CardTitle>
            <CardDescription>
              Nombre d'utilisatrices ayant signalé un symptôme au cours des 6
              derniers mois.
            </CardDescription>
            <div className="w-full flex flex-col xl:flex-row gap-4 items-end pt-3">
              <Select
                value={selectedCyclePhase}
                onValueChange={(value) =>
                  setSelectedCyclePhase(value as CyclePhaseType)
                }
              >
                <SelectTrigger className="w-[250px]">
                  <SelectValue placeholder="Sélectionner une phase" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(CyclePhaseFr).map(([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            <ChartContainer config={chartConfig} className="max-h-[500px]">
              <BarChart
                accessibilityLayer
                data={formattedDistribution}
                layout="vertical"
                margin={{
                  right: 16,
                  top: 16,
                }}
                maxBarSize={50}
              >
                <CartesianGrid horizontal={false} />
                <XAxis type="number" dataKey="users" hide />
                <YAxis
                  dataKey="symptom"
                  type="category"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  hide
                  interval={0}
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent indicator="line" />}
                />

                <Bar dataKey="users" fill="var(--color-users)" radius={4}>
                  <LabelList
                    dataKey="symptom"
                    position="insideLeft"
                    offset={8}
                    className="fill-[--color-label]"
                    fontSize={12}
                  />
                  <LabelList
                    dataKey="users"
                    position="right"
                    offset={-60}
                    className="fill-[--color-label]"
                    fontSize={12}
                    formatter={(value: number) =>
                      `${((value / usersCount) * 100).toFixed(1)} %`
                    }
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
